<template>
  <div>
    <b-row>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="folder"
                font-scale="6"
              />
              <br>Document KYC
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-new-kyc-document' }">
                Nouveau
              </b-link>
              |
              <b-link :to="{ name: 'admin-all-kyc-documents' }">
                Liste
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="person-bounding-box"
                font-scale="6"
              />
              <br>Validation KYC
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-validation-kyc' }">
                Utilisateurs
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="person"
                font-scale="6"
              />
              <br>Avis utilisateur
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-reviews' }">
                Gestion
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="server"
                font-scale="6"
              />
              <br>{{ $t('Payment-Add-Header') }}
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-payment' }">
                {{ $t('Add') }}
              </b-link>
              |
              <b-link :to="{ name: 'admin-settings-allpayment' }">
                {{ $t('Listing') }}
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="cash"
                font-scale="6"
              />
              <br>Solde Manuel
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-manual-account-update' }">
                MaJ
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="joystick"
                font-scale="6"
              />
              <br>{{ $t('Receipt Adress') }}
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-address' }">
                {{ $t('Add') }}
              </b-link>
              |
              <b-link :to="{ name: 'admin-settings-all-address' }">
                {{ $t('Listing') }}
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="graph-up"
                font-scale="6"
              />
              <br>{{ $t('Transaction-History') }}
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-transaction-history' }">
                {{ $t('Check') }}
              </b-link>
              <!-- <b-link :to="{ name: 'admin-settings-sent-state-history' }">
                Paiment Client
              </b-link> -->
            </div>
          </div>
        </b-card>
      </b-col>

      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="headset"
                font-scale="6"
              />
              <br>{{ $t('Support') }}
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-support-listing' }">
                {{ $t('Listing') }}
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="patch-question"
                font-scale="6"
              />
              <br>{{ $t('Faq-Section') }}
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-faq-section-add' }">
                {{ $t('Add') }}
              </b-link>
              |
              <b-link :to="{ name: 'admin-settings-faq-section-listing' }">
                {{ $t('Listing') }}
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="question"
                font-scale="6"
              />
              <br>{{ $t('Faq-Details') }}
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-faq-details-add' }">
                {{ $t('Add') }}
              </b-link>
              |
              <b-link :to="{ name: 'admin-settings-faq-details-listing' }">
                {{ $t('Listing') }}
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col sm="4">
        <b-card>
          <div class="text-center">
            <b-card-title>
              <b-icon
                class="p-2 text-primary"
                icon="newspaper"
                font-scale="6"
              />
              <br>Information
            </b-card-title>
            <div class="p-0">
              <b-link :to="{ name: 'admin-settings-information-add' }">
                Ajouter
              </b-link> |
              <b-link :to="{ name: 'admin-settings-all-information' }">
                Liste
              </b-link>
            </div>
          </div>
        </b-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {

}
</script>
